exports.components = {
  "component---src-pages-404-index-jsx": () => import("./../../../src/pages/404/index.jsx" /* webpackChunkName: "component---src-pages-404-index-jsx" */),
  "component---src-pages-about-index-jsx": () => import("./../../../src/pages/about/index.jsx" /* webpackChunkName: "component---src-pages-about-index-jsx" */),
  "component---src-pages-authors-index-js": () => import("./../../../src/pages/authors/index.js" /* webpackChunkName: "component---src-pages-authors-index-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-chuvstvitelni-periodi-index-jsx": () => import("./../../../src/pages/chuvstvitelni-periodi/index.jsx" /* webpackChunkName: "component---src-pages-chuvstvitelni-periodi-index-jsx" */),
  "component---src-pages-contacts-index-jsx": () => import("./../../../src/pages/contacts/index.jsx" /* webpackChunkName: "component---src-pages-contacts-index-jsx" */),
  "component---src-pages-cookie-policy-index-jsx": () => import("./../../../src/pages/cookie-policy/index.jsx" /* webpackChunkName: "component---src-pages-cookie-policy-index-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-montesori-metod-index-jsx": () => import("./../../../src/pages/montesori-metod/index.jsx" /* webpackChunkName: "component---src-pages-montesori-metod-index-jsx" */),
  "component---src-pages-privacy-policy-index-jsx": () => import("./../../../src/pages/privacy-policy/index.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-jsx" */),
  "component---src-pages-qr-index-jsx": () => import("./../../../src/pages/qr/index.jsx" /* webpackChunkName: "component---src-pages-qr-index-jsx" */),
  "component---src-pages-quiz-index-jsx": () => import("./../../../src/pages/quiz/index.jsx" /* webpackChunkName: "component---src-pages-quiz-index-jsx" */),
  "component---src-pages-terms-and-conditions-index-jsx": () => import("./../../../src/pages/terms-and-conditions/index.jsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-index-jsx" */),
  "component---src-pages-uprazhnenia-index-jsx": () => import("./../../../src/pages/uprazhnenia/index.jsx" /* webpackChunkName: "component---src-pages-uprazhnenia-index-jsx" */),
  "component---src-templates-article-index-jsx": () => import("./../../../src/templates/article/index.jsx" /* webpackChunkName: "component---src-templates-article-index-jsx" */),
  "component---src-templates-author-index-jsx": () => import("./../../../src/templates/author/index.jsx" /* webpackChunkName: "component---src-templates-author-index-jsx" */),
  "component---src-templates-exercise-2-index-jsx": () => import("./../../../src/templates/exercise2/index.jsx" /* webpackChunkName: "component---src-templates-exercise-2-index-jsx" */),
  "component---src-templates-period-index-jsx": () => import("./../../../src/templates/period/index.jsx" /* webpackChunkName: "component---src-templates-period-index-jsx" */)
}

